import moment from 'moment'

export const barChartOption = {
  grid: {
    top: 30,
    left: 0,
    right: 0,
    bottom: 0,
    containLabel: true
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      formatter: function (value) {
        return moment(value).format('M/DD')
      }
    },
    data: []
  },
  yAxis: {
    type: 'value'
  },
  tooltip: {
    trigger: 'item'
  },
  series: [
    {
      type: 'bar',
      data: []
    }
  ],
  color: ['#1890ff']
}

export const lineChartOption = {
  grid: {
    top: 30,
    left: 0,
    right: 0,
    bottom: 0,
    containLabel: true
  },
  xAxis: {
    type: 'category',
    axisLabel: {
      formatter: function (value) {
        return moment(value).format('M/DD')
      }
    },
    data: []
  },
  yAxis: {
    type: 'value'
  },
  tooltip: {
    trigger: 'axis'
  },
  series: [
    {
      type: 'line',
      smooth: true,
      data: []
    }
  ],
  color: ['#1890ff']
}
