<template>
  <a-form-model
    class="week-form"
    ref="form"
    :model="form"
    :rules="rules"
  >
    <a-row :gutter="40">
      <a-col :span="8">
        <a-form-model-item label="问题总结" prop="summary">
          <a-textarea
            v-model="form.summary"
            :disabled="mode === 'check'"
            :rows="4"
            placeholder="请输入问题总结"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="改进措施" prop="measure">
          <a-textarea
            v-model="form.measure"
            :disabled="mode === 'check'"
            :rows="4"
            placeholder="请输入改进措施"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8" v-if="timeType === 3">
        <a-form-model-item label="潜力判定" prop="potentiality">
          <a-select
            v-model="form.potentiality"
            :disabled="mode === 'check'"
            placeholder="请选择潜力判定"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in potentialityList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>  
      </a-col>
    </a-row>
  </a-form-model>  
</template>

<script>
export default {
  name: 'WeekForm',
  props: {
    mode: {
      type: String,
      default: 'edit'
    },
    timeType: {
      type: Number,
      default: 2 // 2: week, 3: month
    },
    form: {
      type: Object,
      default () { return {} }
    },
    potentialityList: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      rules: {
        summary: [
          { required: true, message: '请输入问题总结', trigger: 'blur' },
          { max: 200, message: '最多可输入200字', trigger: 'blur' }
        ],
        measure: [
          { required: true, message: '请输入改进措施', trigger: 'blur' },
          { max: 200, message: '最多可输入200字', trigger: 'blur' }
        ],
        potentiality: [
          { required: true, message: '请选择潜力判定', trigger: 'blur' },  
        ]
      }
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      }) 
    }  
  }
}
</script>