<template>
  <a-card class="page-container">
    <h1>
      <span>{{ dealerInfo.principalName || '--' }}</span>
      <span>{{ dealerInfo.areaName || '--' }}</span>
      <span>{{ dealerInfo.dealerName || '--' }}</span>      
    </h1>

    <a-tabs v-model="activeTab" @change="handleTabsChange">
      <a-tab-pane tab="直播" :key="1">
        <div class="author-wrap">
          <a-select
            class="mr-10"
            v-model="liveAuthorId"
            style="width: 300px;"
            @change="setLiveChartOption();setLiveTargetData()"
          >
            <a-select-option
              v-for="item in liveAuthorList"
              :key="item.authorId"
              :value="item.authorId"
            >
              （{{ item.platform }}）{{ item.authorName }}
            </a-select-option>
          </a-select>
          <a-radio-group
            class="mr-10"
            v-model="liveChartType"
            button-style="solid"
            @change="setLiveChartOption"
          >
            <a-radio-button
              v-for="item in liveChartTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
          <span v-if="timeType === 2">近四周数据走势</span>
          <span v-if="timeType === 3">近2月发布的视频数据走势</span>
        </div>

        <div class="chart-wrap">
          <base-chart
            :option="liveChartOption"
          />
        </div>

        <div class="data-wrap">
          <div class="data-group">
            <div class="data-group__hd">关键指标</div>
            <div class="data-group__bd">
              <div class="data-item">
                <div class="data-item__label">直播</div>
                <div class="data-item__value">
                  {{ liveTargetData.liveTotal }}
                </div>
              </div>

               <div class="data-item">
                <div class="data-item__label">观众</div>
                <div class="data-item__value">
                  {{ liveTargetData.liveWatchUser }}
                </div>
              </div>

               <div class="data-item">
                <div class="data-item__label">场均观看</div>
                <div class="data-item__value">
                  {{ liveTargetData.avgLiveWatchUser }}
                </div>
              </div>
            </div>
          </div>

          <div class="data-group">
            <div class="data-group__hd">环比上周</div>
            <div class="data-group__bd">
              <div
                class="data-item"
                :class="{
                  'data-item--up': liveTargetData.liveTotalCompare > 0,
                  'data-item--down': liveTargetData.liveTotalCompare < 0
                }"
              >
                <div class="data-item__value">
                  {{ liveTargetData.liveTotalCompare }}
                </div>
              </div>

              <div
                class="data-item"
                :class="{
                  'data-item--up': liveTargetData.liveWatchUserCompare > 0,
                  'data-item--down': liveTargetData.liveWatchUserCompare < 0
                }"
               >
                <div class="data-item__value">
                  {{ liveTargetData.liveWatchUserCompare }}
                </div>
              </div>

              <div
                class="data-item"
                :class="{
                  'data-item--up': liveTargetData.avgLiveWatchUserCompare > 0,
                  'data-item--down': liveTargetData.avgLiveWatchUserCompare < 0
                }"
               >
                <div class="data-item__value">
                  {{ liveTargetData.avgLiveWatchUserCompare }}
                </div>
              </div>  
            </div>
          </div>  
        </div>
      </a-tab-pane>
      <a-tab-pane tab="短视频" :key="2">
        <div class="author-wrap">
          <a-select
            class="mr-10"
            v-model="awemeAuthorId"
            style="width: 300px;"
            @change="setAwemeChartOption();setAwemeTargetData()"
          >
            <a-select-option
              v-for="item in awemeAuthorList"
              :key="item.authorId"
              :value="item.authorId"
            >
              （{{ item.platform }}）{{ item.authorName }}
            </a-select-option>
          </a-select>
          <a-radio-group
            class="mr-10"
            v-model="awemeChartType"
            button-style="solid"
            @change="setAwemeChartOption()"
          >
            <a-radio-button
              v-for="item in awemeChartTypeList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
          <span v-if="timeType === 2">近四周数据走势</span>
          <span v-if="timeType === 3">近2月发布的视频数据走势</span>
        </div>

        <div class="chart-wrap">
          <base-chart
            :option="awemeChartOption"
          />
        </div>

        <div class="data-wrap">
          <div class="data-group">
            <div class="data-group__hd">关键指标</div>
            <div class="data-group__bd">
              <div class="data-item">
                <div class="data-item__label">视频</div>
                <div class="data-item__value">
                  {{ awemeTargetData.awemeTotal }}
                </div>
              </div>

               <div class="data-item">
                <div class="data-item__label">播放</div>
                <div class="data-item__value">
                  {{ awemeTargetData.playVolume }}
                </div>
              </div>

               <div class="data-item">
                <div class="data-item__label">场均</div>
                <div class="data-item__value">
                  {{ awemeTargetData.avgPlayVolume }}
                </div>
              </div>
            </div>
          </div>

          <div class="data-group">
            <div class="data-group__hd">环比上周</div>
            <div class="data-group__bd">
              <div
                class="data-item"
                :class="{
                  'data-item--up': awemeTargetData.awemeTotalCompare > 0,
                  'data-item--down': awemeTargetData.awemeTotalCompare < 0
                }"
              >
                <div class="data-item__value">
                  {{ awemeTargetData.awemeTotalCompare }}
                </div>
              </div>

              <div
                class="data-item"
                :class="{
                  'data-item--up': awemeTargetData.playVolumeCompare > 0,
                  'data-item--down': awemeTargetData.playVolumeCompare < 0
                }"
              >
                <div class="data-item__value">
                  {{ awemeTargetData.playVolumeCompare }}
                </div>
              </div>

               <div
                class="data-item"
                :class="{
                  'data-item--up': awemeTargetData.avgPlayVolumeCompare > 0,
                  'data-item--down': awemeTargetData.avgPlayVolumeCompare < 0
                }"
              >
                <div class="data-item__value">
                  {{ awemeTargetData.avgPlayVolumeCompare }}
                </div>
              </div>  
            </div>
          </div>  
        </div>
      </a-tab-pane>
    </a-tabs>

    <a-divider />
    <div class="title">{{ timeText }}分析记录：</div>
    <div class="content">
      <summary-form
        ref="summaryForm"
        :mode="mode"
        :time-type="timeType"
        :form="summaryForm"
        :potentiality-list="potentialityList"
      />
    </div>

    <div class="btn-wrap" v-if="mode === 'edit'">
      <a-button
        type="primary"
        :loading="submitLoading"
        @click="handleSubmit"
      >
        提交  
      </a-button>
    </div>
  </a-card>
</template>

<script>
import BaseChart from '@/components/BaseChart'
import SummaryForm from './components/SummaryForm'
import { potentialityList } from '@/utils/fhlUserConst'
import deepClone from '@/utils/deepClone'
import { barChartOption, lineChartOption } from './constant'
import activityApi from '@/api/activity'

export default {
  name: 'actContentDetail',
  components: {
    BaseChart,
    SummaryForm
  },
  data () {
    return {
      mode: 'edit',
      timeType: '',
      dealerInfo: {},
      activeTab: 1,
      liveAuthorList: [],
      liveAuthorId: '',
      liveChartTypeList: [
        { label: '直播时长/min', value: 'liveTime' },
        { label: '观众数/人', value: 'liveWatchUser' }
      ],
      liveChartType: 'liveTime',
      liveChartOption: {},
      liveTargetData: {},
      awemeAuthorList: [],
      awemeAuthorId: '',
      awemeChartTypeList: [
        { label: '播放数', value: 'playVolume' },
        { label: '点赞数', value: 'diggCount' },
        { label: '评论数', value: 'commentCount' },
        { label: '转发量', value: 'shareCount' }
      ],
      awemeChartType: 'playVolume',
      awemeChartOption: {},
      awemeTargetData: {},
      summaryForm: {
        summary: '',
        measure: '',
        potentiality: ''
      },
      potentialityList,
      submitLoading: false
    }
  },
  created () {
    const { submitStatus, timeType, principalName, areaName, dealerName } = this.$route.query

    this.mode = Number(submitStatus) === 1 ? 'edit' : 'check'
    this.timeType = Number(timeType)
    this.dealerInfo = { principalName, areaName, dealerName }
    this.fetchLiveDataLeader()

    if (this.timeType === 2) {
      this.fetchWeekDetailLeader()
    } else {
      this.fetchMonthDetailLeader()
    }
  },
  computed: {
    timeText () {
      return this.timeType === 2 ? '周' : '月'
    }
  },
  methods: {
    handleTabsChange () {
      if (!this.awemeData) {
        this.fetchAwemeDataLeader()
      }
    },
    // 获取直播数据（运营组长）
    async fetchLiveDataLeader () {
      try {
        const params = {
          activityId: this.$route.query.activityId,
          dealerId: this.$route.query.dealerId  
        }
        const { code, data, message } = await activityApi.fetchLiveDataLeader(params)

        if (code === 200) {
          if (this.timeType === 2) {
            this.liveData = data.weekChart || []
          }

          if (this.timeType === 3) {
            this.liveData = data.monthChart || []  
          }

          this.setLiveAuthorList()
          this.setLiveAuthorId()
          this.setLiveChartOption()
          this.setLiveTargetData()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)  
      }
    },
    setLiveAuthorList () {
      this.liveAuthorList = this.liveData.map(item => {
        const { platform, authorId, authorName } = item

        return { platform, authorId, authorName }
      })
    },
    setLiveAuthorId () {
      if (this.liveAuthorList.length > 0) {
        this.liveAuthorId = this.liveAuthorList[0].authorId
      } else {
        this.liveAuthorId = ''
      }
    },
    setLiveChartOption () {
      if (this.liveAuthorId) {
        const index = this.liveData.findIndex(item => item.authorId === this.liveAuthorId)
        let option = deepClone(this.timeType === 2 ? barChartOption : lineChartOption)

        option.xAxis.data = this.liveData[index].chartList.map(item => item.xaxis)
        option.series[0].data = this.liveData[index].chartList.map(item => item[this.liveChartType])

        this.liveChartOption = option
      } else {
        this.liveChartOption = {}  
      }
    },
    setLiveTargetData () {
      if (this.liveAuthorId) {
        const index = this.liveData.findIndex(item => item.authorId === this.liveAuthorId)
        const {
          liveTotal,
          liveTotalCompare,
          liveWatchUser,
          liveWatchUserCompare,
          avgLiveWatchUser,
          avgLiveWatchUserCompare
        } = this.liveData[index]

        this.liveTargetData = { 
          liveTotal,
          liveTotalCompare,
          liveWatchUser,
          liveWatchUserCompare,
          avgLiveWatchUser,
          avgLiveWatchUserCompare   
        }
      } else {
        this.liveTargetData = {}
      }
    },
    // 获取短视频数据（运营组长）
    async fetchAwemeDataLeader () {
      try {
        const params = {
          activityId: this.$route.query.activityId,
          dealerId: this.$route.query.dealerId
        }
        const { code, data, message } = await activityApi.fetchAwemeDataLeader(params)

        if (code === 200) {
          if (this.timeType === 2) {
            this.awemeData = data.weekChart || []
          }

          if (this.timeType === 3) {
            this.awemeData = data.monthChart || []  
          }

          this.setAwemeAuthorList()
          this.setAwemeAuthorId()
          this.setAwemeChartOption()
          this.setAwemeTargetData()
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    setAwemeAuthorList () {
      this.awemeAuthorList = this.awemeData.map(item => {
        const { platform, authorId, authorName } = item

        return { platform, authorId, authorName }
      })
    },
    setAwemeAuthorId () {
      if (this.awemeAuthorList.length > 0) {
        this.awemeAuthorId = this.awemeAuthorList[0].authorId
      } else {
        this.awemeAuthorId = ''
      }
    },
    setAwemeChartOption () {
      if (this.awemeAuthorId) {
        const index = this.awemeData.findIndex(item => item.authorId === this.awemeAuthorId)
        let option = deepClone(this.timeType === 2 ? barChartOption : lineChartOption)

        option.xAxis.data = this.awemeData[index].chartList.map(item => item.xaxis)
        option.series[0].data = this.awemeData[index].chartList.map(item => item[this.awemeChartType])

        this.awemeChartOption = option
      } else {
        this.awemeChartOption = {}
      }
    },
    setAwemeTargetData () {
      if (this.awemeAuthorId) {
        const index = this.awemeData.findIndex(item => item.authorId === this.awemeAuthorId)
        const {
          awemeTotal,
          awemeTotalCompare,
          playVolume,
          playVolumeCompare,
          avgPlayVolume,
          avgPlayVolumeCompare
        } = this.awemeData[index]

        this.awemeTargetData = { 
          awemeTotal,
          awemeTotalCompare,
          playVolume,
          playVolumeCompare,
          avgPlayVolume,
          avgPlayVolumeCompare
        }
      } else {
        this.awemeTargetData = {}
      }
    },
    // 获取周详情（运营组长）
    async fetchWeekDetailLeader () {
      try {
        const params = {
          id: this.$route.query.id
        }
        const { code, data, message } = await activityApi.fetchWeekDetailLeader(params)

        if (code === 200) {
          if (data) {
            this.summaryForm.summary = data.summary
            this.summaryForm.measure = data.measure
          }
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 获取月详情（运营组长）
    async fetchMonthDetailLeader () {
      try {
        const params = {
          id: this.$route.query.id
        }
        const { code, data, message } = await activityApi.fetchMonthDetailLeader(params)

        if (code === 200) {
          if (data) {
            this.summaryForm.summary = data.summary
            this.summaryForm.measure = data.measure
            this.summaryForm.potentiality = data.potentiality
          }
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 处理提交
    async handleSubmit () {
      try {
        const valid = await this.$refs.summaryForm.validate()

        if (valid) {
          this.timeType === 2 && this.submitWeekSummaryLeader()
          this.timeType === 3 && this.submitMonthSummaryLeader()
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 提交周总结（运营组长）
    async submitWeekSummaryLeader () {
      this.submitLoading = true
      try {
        const {
          activityId,
          dealerId,
          userId,
          id,
          ctime,
          submitStatus
        } = this.$route.query
        const params = {
          activityId,
          dealerId,
          userId,
          id,
          ctime,
          submitStatus,
          summary: this.summaryForm.summary,
          measure: this.summaryForm.measure
        }
        const { code, message } = await activityApi.submitWeekSummaryLeader(params)

        if (code === 200) {
          this.$router.back()
        } else {
          this.$message.error(message)
        }

        this.submitLoading = false
      } catch (e) {
        console.log(e)
        this.submitLoading = false
      }
    },
    // 提交月总结（运营组长）
    async submitMonthSummaryLeader () {
      this.submitLoading = true
      try {
        const {
          activityId,
          dealerId,
          userId,
          id,
          ctime,
          submitStatus
        } = this.$route.query
        const params = {
          activityId,
          dealerId,
          userId,
          id,
          ctime,
          submitType: submitStatus,
          summary: this.summaryForm.summary,
          measure: this.summaryForm.measure,
          potentiality: this.summaryForm.potentiality
        }
        const { code, message } = await activityApi.submitMonthSummaryLeader(params)

        if (code === 200) {
          this.$router.back()
        } else {
          this.$message.error(message)
        }

        this.submitLoading = false
      } catch (e) {
        console.log(e)
        this.submitLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

.mr-10 {
  margin-right: 10px;
}

h1 {
  span {
    margin: 0 20px 0 0;
  }
}

.chart-wrap {
  margin: 16px 0 0;
  padding: 20px;
  background: #e8e8e8;
  border-radius: 10px;
}

.data-wrap {
  margin: 30px 0 0;

  .data-group {
    display: flex;
    align-items: center;

    &__hd {
      display: flex;
      align-items: center;
      padding: 0 20px;
      width: 70px;
      border-right: 1px solid #e8e8e8;
    }

    &__bd {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 30px;
    }

    & + .data-group {
      margin: 20px 0 0;
    }
  }

  .data-item {
    flex-shrink: 0;
    min-width: 100px;

    &__value {
      font-size: 18px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
    }

    &--up {
      .data-item {
        &__value {
          color: #f00;
        }
      }
    }

    &--down {
      .data-item {
        &__value {
          color: #0f0;
        }
      }
    }
  }
}

.title {
  padding: 0 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.content {
  padding: 0 20px;
}

.btn-wrap {
  margin: 40px 0 0;
  text-align: center;
}
</style>
